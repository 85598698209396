import React, { useState, useEffect } from "react";

const Checkbox = ({ handleCheckboxChange, label, checked, ...rest }) => {
  const [isChecked, setIsChecked] = useState(false);

  const toggleCheckboxChange = (e) => {
    setIsChecked((isChecked) => !isChecked);
    handleCheckboxChange(e);
  };

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  return (
    <label>
      <input
        type="checkbox"
        value={label}
        checked={isChecked}
        onChange={(e) => toggleCheckboxChange(e)}
        {...rest}
      />

      {label}
    </label>
  );
};

// Checkbox.propTypes = {
//   label: PropTypes.string.isRequired,
//   handleCheckboxChange: PropTypes.func.isRequired,
// };

export default Checkbox;
