import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import reducer from './storage/users';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

const users = createStore(reducer);
const rootElement = document.getElementById('root');
document.getElementById('loading').remove();

ReactDOM.render(
  <Provider store={users}>
    <App />
  </Provider>,
  rootElement,
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>,
  // document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
